import { keyboardOutline } from '@loveholidays/design-system';
import { Icon } from '@loveholidays/design-system';
import React, { useEffect, useRef } from 'react';

import { ClassNameProps } from '@ComponentProps';

interface SelectionTagProps extends ClassNameProps {
  label: string;
  onClick: () => void;
}

const focusableSelector =
  'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])';

export const SelectionTag: React.FC<SelectionTagProps> = ({ label, onClick, className }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const node = ref.current;

    return () => {
      if (node?.nextElementSibling) {
        (node.nextElementSibling?.querySelector(focusableSelector) as HTMLElement)?.focus();

        return;
      }

      if (node?.previousElementSibling) {
        (node.previousElementSibling?.querySelector(focusableSelector) as HTMLElement)?.focus();

        return;
      }

      if (node?.parentElement) {
        (node.parentElement?.closest(focusableSelector) as HTMLElement)?.focus();
      }
    };
  }, []);

  return (
    <div
      ref={ref}
      data-id="selection-tag"
      className={className}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flex: '0 0 auto',
        backgroundColor: 'selectionTag',
        maxHeight: '36px',
        lineHeight: 'normal',
        color: 'textDefault',
        whiteSpace: 'nowrap',
      }}
    >
      <span
        sx={{
          padding: '3xs',
        }}
      >
        {label}
      </span>
      <button
        aria-label="remove"
        data-id="selection-tag-remove-button"
        type="button"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClick();
        }}
        sx={{
          width: '30px',
          minWidth: '30px',
          backgroundColor: 'selectionTagClose',
          color: 'white',
          textAlign: 'center',
          cursor: 'pointer',
          border: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxHeight: '100%',
          ...keyboardOutline,
        }}
      >
        <Icon
          name="Navigation/Close"
          size="20"
        />
      </button>
    </div>
  );
};
