import { TriggerButton, TriggerEvent } from '@loveholidays/design-system';
import React from 'react';
import { SxStyleProp } from 'theme-ui';

import { ClassNameProps } from '@ComponentProps';

interface DayProps extends ClassNameProps {
  'aria-label'?: string;
  selected?: boolean;
  disabled?: boolean;
  onClick: (e: TriggerEvent) => void;
}

interface DayPriceProps {
  isCheapest: boolean;
}

export const DayPrice: React.FC<DayPriceProps> = ({ isCheapest, children }) => (
  <div
    data-id="day-price"
    sx={{
      color: isCheapest ? 'calendarCheapestPrice' : 'calendarPrice',
    }}
  >
    {children}
  </div>
);

export const DayLabel: React.FC = ({ children, ...rest }) => (
  <div
    sx={{
      display: 'flex',
      justifyContent: 'space-around',
      padding: '10% 0',
    }}
    {...rest}
  >
    {children}
  </div>
);

const enabledStyles: SxStyleProp = {
  color: 'textDefault',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: 'calendarDayHoverBackground',
  },
};

const disabledStyles: SxStyleProp = {
  color: 'inputDisabled',
  cursor: 'default',
};

export const Day: React.FC<DayProps> = ({
  disabled,
  selected,
  children,
  onClick,
  className,
  'data-id': dataId,
  'aria-label': ariaLabel,
}) => (
  <TriggerButton
    aria-label={ariaLabel}
    className={className}
    data-id={dataId}
    disabled={disabled}
    onTrigger={onClick}
    sx={{
      position: 'absolute',
      width: '100%',
      height: '100%',
      padding: '5xs',
      boxShadow: (theme) =>
        selected ? `inset 0 0 0 2px ${theme.colors.calendarDaySelectedBorder}` : 'none',
      textAlign: 'center',
      fontWeight: selected ? 'bold' : 'normal',
      fontSize: ['xs', 's'],
      ...(disabled ? disabledStyles : enabledStyles),
    }}
  >
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  </TriggerButton>
);
