import React, { useEffect } from 'react';

export const useFocusOut = (
  ref: React.RefObject<HTMLElement>,
  cb: () => void,
  deps: any[] = [],
) => {
  useEffect(() => {
    // To avoid calling `cb` after the element unmounted. see https://stackoverflow.com/a/60907638
    let isMounted = true;

    // Check If new focused element is inside the ref container:
    // if `Yes` then do nothing
    // if `No` then will call `cb` because `ref` lost focus
    // Note: the `FocusOut` logic based on `focusin` event helps to avoid blinking of focus when we used timeout functions.
    const handler = (event: FocusEvent) => {
      if (isMounted && ref.current && event.target instanceof Node) {
        const isActiveElementInTheRef = ref.current.contains(event.target);
        if (!isActiveElementInTheRef) {
          cb();
        }
      }
    };

    document.addEventListener('focusin', handler, { passive: true });

    return () => {
      isMounted = false;
      document.removeEventListener('focusin', handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
