import { TriggerButton, useOnKeyEvent, Skeleton, Key } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { useState, useRef, useEffect, forwardRef, ReactNode } from 'react';

import { MultiSelectOptionType, MultiSelectOption } from './MultiSelectOption';
import { addSentryBreadcrumb } from '@Core/addSentryBreadcrumb';
import { Option } from '@UX/Forms/MultiSelect/MultiSelect';

interface MultiSelectListProps {
  options: Option[];
  selectedOptions: Option[];
  onUpdateOptions: (newOptions: Option[]) => void;
  optionRenderer?: (props: MultiSelectOptionType) => ReactNode;
  isLoading?: boolean;
  'data-id'?: string;
  focusOnMount?: boolean;
}

/** Delay for preventing MouseEnter on KeyUp event */
const TIMEOUT_MOUSE_ENTER_ON_ARROW_KEY_MS = 1000;

export const MultiSelectList = forwardRef<HTMLDivElement, MultiSelectListProps>(
  (
    {
      onUpdateOptions,
      options,
      selectedOptions,
      optionRenderer,
      isLoading,
      'data-id': dataId,
      focusOnMount = true,
    },
    ref,
  ) => {
    const [focusIndex, setFocusIndex] = useState<number>(0);
    const [arrowClickTime, setArrowClickTime] = useState<number>(0);
    const optionRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();
    const mountedRef = useRef<boolean>(false);

    useEffect(() => {
      mountedRef.current = true;
    }, []);

    useEffect(() => {
      if (!mountedRef.current || focusOnMount) {
        optionRef?.current?.focus();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focusIndex]);

    const getNextAvailableFocusIndex = (
      index: number,
      action: 'increment' | 'decrement',
    ): number => {
      const step = action === 'increment' ? 1 : -1;

      // if the user is on the first option and presses "up", then we stay on the current option
      if (index === 0 && step < 0) {
        return index;
      }

      // if the user is on the last option and presses "down", then we stay on the current option
      if (options.length - 1 === index && step > 0) {
        return index;
      }

      // if the user is not on the first/last option,
      // then we return the next available option in the direction the user is navigating ("up" or "down")
      for (let i = index + step; i >= 0 && i < options.length; i += step) {
        if (options[i].available) {
          return i;
        }
      }

      // in all the other cases (e.g. the user is on the middle option, presses "down"
      // but all the options that way are unavailable) we stay on the current index
      return index;
    };

    useOnKeyEvent(
      Key.ArrowUp,
      () => {
        setArrowClickTime(Date.now());
        setFocusIndex((curIndex) => getNextAvailableFocusIndex(curIndex, 'decrement'));
      },
      [options],
    );

    useOnKeyEvent(
      Key.ArrowDown,
      () => {
        setArrowClickTime(Date.now());
        setFocusIndex((curIndex) => getNextAvailableFocusIndex(curIndex, 'increment'));
      },
      [options],
    );

    const handleMouseEnter = (value: string) => {
      if (arrowClickTime + TIMEOUT_MOUSE_ENTER_ON_ARROW_KEY_MS > Date.now()) {
        setArrowClickTime(0);

        return;
      }
      const optionIndex = options.findIndex((option) => option.value === value);
      setFocusIndex(optionIndex);
    };

    const getNewSelectedOptions = (option: Option) => {
      let newOptions;
      const isOptionSelected = !!selectedOptions.find((item) => item.value === option.value);

      if (isOptionSelected) {
        newOptions = selectedOptions.filter((item) => item.value !== option.value);
      } else {
        newOptions = selectedOptions.concat([option]);
      }

      return newOptions;
    };

    return (
      <div
        ref={ref}
        role="presentation"
        onClick={(evt) => {
          if (evt.target === evt.currentTarget) {
            optionRef.current?.click();
          }
        }}
      >
        <ul data-id={`${dataId ? `${dataId}-` : ''}multi-select-list`}>
          {isLoading &&
            [...Array(7).keys()].map((_, index) => (
              <li
                key={index}
                sx={{
                  borderWidth: 'outlinedStrokeWeight',
                  borderStyle: 'solid',
                  borderColor: 'inputBorder',
                  padding: '2xs',
                }}
              >
                <Skeleton sx={{ width: '100%' }} />
              </li>
            ))}

          {!isLoading && !options.length && (
            <li
              sx={{
                padding: '2xs',
              }}
            >
              <p>{t('noMatchesFound')}</p>
            </li>
          )}

          {!isLoading &&
            options.map((option, index) => {
              const isFocused = index === focusIndex;
              const isDisabled = !option.available;
              const isSelected = !!selectedOptions.find((item) => item.value === option.value);

              return (
                <li
                  key={index}
                  role="option"
                  aria-selected={isSelected}
                  sx={{
                    borderBottomWidth: 'outlinedStrokeWeight',
                    borderBottomStyle: 'solid',
                    borderBottomColor: 'inputBorder',
                  }}
                >
                  <TriggerButton
                    data-id="multi-select-option"
                    disabled={isDisabled}
                    role="checkbox"
                    aria-checked={isSelected}
                    onTrigger={(e) => {
                      if (option.onClick) {
                        option.onClick(option);

                        return;
                      }

                      e.preventDefault();
                      const newOptions = getNewSelectedOptions(option);
                      onUpdateOptions(newOptions);
                      addSentryBreadcrumb('MultiSelect option clicked', { option });
                    }}
                    onMouseEnter={() => handleMouseEnter(option.value)}
                    ref={isFocused ? optionRef : undefined}
                    sx={{
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    {optionRenderer && typeof optionRenderer === 'function' ? (
                      optionRenderer({ option, isSelected, isFocused })
                    ) : (
                      <MultiSelectOption
                        isSelected={isSelected}
                        isFocused={isFocused}
                        option={option}
                      />
                    )}
                  </TriggerButton>
                </li>
              );
            })}
        </ul>
      </div>
    );
  },
);
