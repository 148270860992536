import { Check } from '@loveholidays/design-system';
import React, { ReactNode } from 'react';

import { Option } from '@UX/Forms/MultiSelect/MultiSelect';

export interface MultiSelectOptionType {
  isSelected?: boolean;
  isFocused?: boolean;
  option: Option;
}

interface MultiSelectOptionProps extends MultiSelectOptionType {
  canBeChecked?: boolean;
  children?: ReactNode;
}

export const MultiSelectOption: React.FC<MultiSelectOptionProps> = ({
  canBeChecked = true,
  isSelected = false,
  isFocused = false,
  option,
  children,
}) => {
  const isDisabled = !option.available;

  return (
    <div
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '2xs',
        cursor: isDisabled ? 'auto' : 'pointer',
        backgroundColor: isFocused && !isDisabled ? 'selectOptionHover' : 'background',

        '&:active&:not(:disabled)': {
          color: 'selectOptionHover',
        },

        ...(canBeChecked && {
          flexDirection: ['row-reverse', 'row'],
          justifyContent: ['space-between', 'flex-start'],
        }),
      }}
    >
      {canBeChecked && (
        <div
          sx={{
            display: 'flex',
            marginRight: '3xs',
          }}
        >
          <Check
            checked={isSelected}
            disabled={isDisabled}
            onChange={(isSelected) => isSelected}
          />
        </div>
      )}
      {children || (
        <div
          sx={{
            color: !option.available ? 'textDisabled' : 'textDefault',
            textAlign: 'left',
            fontWeight: isSelected ? 'bold' : 'normal',
          }}
        >
          {option.label}
        </div>
      )}
    </div>
  );
};
