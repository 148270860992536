import { Icon, ModalHeader, keyboardOutline } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { Fragment, useCallback } from 'react';

import { SearchSelectionDescription } from './SearchSelectionDescription';
import { RoomConfiguration } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { LoadableModal } from '@Components/Modal/LoadableModal';
import { useModal } from '@Components/Modal/useModal';
import { LoadableRoomsEditor } from '@Components/RoomsEditor/LoadableRoomsEditor';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';
import { usePriceQuoteUnit } from '@Core/prices/usePriceQuoteUnit';
import { trackEventClick } from '@Core/tracking/hooks/useInteractionTracking';
import { isFamily } from '@Stores/SearchSelectionStore';
import { useSearchSelectionStore } from '@Stores/StoreContext';
import { IconInput } from '@UX/Forms/IconInput/IconInput';

interface RoomsInputProps extends ClassNameProps {
  isFlatIcon?: boolean;
}

export const RoomsInput: React.FC<RoomsInputProps> = ({ className, isFlatIcon }) => {
  const [rooms, setRooms] = useSearchSelectionStore((state) => [state.rooms, state.setRooms]);
  const showTotalPriceForFamilies = useFeatureFlag('ShowTotalPriceForFamilies');
  const { setPriceQuoteUnit } = usePriceQuoteUnit();
  const [isOpen, setModalOpen, setModalClose] = useModal();
  const { t } = useTranslation();

  const handleApply = (rooms: RoomConfiguration[]) => {
    setRooms(rooms);
    if (isFamily(rooms) && showTotalPriceForFamilies) {
      setPriceQuoteUnit('TOTAL');
    }
    setModalClose();
  };

  return (
    <Fragment>
      <IconInput
        data-id="rooms-input"
        className={className}
        icon={
          <Icon
            name="Content/Customer"
            size="20"
          />
        }
        sx={{
          ...keyboardOutline,
        }}
        role="button"
        onClick={useCallback(() => {
          setModalOpen();
          trackEventClick('search-ui-guest-input');
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])}
        tabIndex={0}
        isFlatIcon={isFlatIcon}
      >
        <SearchSelectionDescription />
      </IconInput>
      <LoadableModal
        show={isOpen}
        onClose={setModalClose}
        data-id="rooms-input-modal"
        Header={
          <ModalHeader
            layout="medium"
            onClose={setModalClose}
            label={t('rooms.contentTitle')}
          />
        }
        Content={
          <LoadableRoomsEditor
            rooms={rooms}
            onApply={handleApply}
            onCancel={setModalClose}
          />
        }
      />
    </Fragment>
  );
};
