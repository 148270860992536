import { ModalComponentOrElement } from '@loveholidays/design-system/lib/components/Modal/utils';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { ReactNode, useState, HTMLProps, Ref, useEffect, Fragment } from 'react';

import { MultiSelectList } from './MultiSelectList';
import { MultiSelectOptionType } from './MultiSelectOption';
import { Button } from '@Components/Button/Button';
import { LoadableModal } from '@Components/Modal/LoadableModal';
import { Option } from '@UX/Forms/MultiSelect/MultiSelect';

interface DropdownOrModalProps extends HTMLProps<HTMLDivElement> {
  isMobile?: boolean;
  isModalOpen?: boolean;
  modalHeaderContent?: ReactNode;
  onClose?: () => void;
  onOptionsUpdate: (options: Option[]) => void;
  options: Option[];
  selectedOptions: Option[];
  filterOptions?: (selectedOptions: Option[]) => Option[];
  multiSelectListRef: Ref<HTMLDivElement>;
  optionRenderer?: (props: MultiSelectOptionType) => ReactNode;
  isLoading?: boolean;
  'data-id'?: string;
}

export const DropdownOrModal: React.FC<DropdownOrModalProps> = ({
  options,
  isMobile,
  isModalOpen,
  modalHeaderContent,
  onClose = () => {},
  selectedOptions,
  onOptionsUpdate,
  filterOptions = (opts) => opts,
  multiSelectListRef,
  optionRenderer,
  isLoading,
  'data-id': dataId,
}) => {
  const { t } = useTranslation();
  const [tempSelectedOptions, setTempSelectedOptions] = useState(selectedOptions);

  useEffect(() => {
    setTempSelectedOptions(selectedOptions);
  }, [selectedOptions]);

  const onApply = () => {
    onOptionsUpdate(tempSelectedOptions);
    onClose();
  };

  if (isMobile) {
    return isModalOpen ? (
      <LoadableModal
        onClose={() => {
          onOptionsUpdate(tempSelectedOptions);
          onClose();
        }}
        show
        data-id="modal-container"
        noPadding
        Header={modalHeaderContent as ModalComponentOrElement}
        Actions={
          <Fragment>
            <Button
              data-id="cancel-btn"
              variant="Outline"
              size="48"
              stretch
              onClick={onClose}
            >
              {t('cancel')}
            </Button>
            <Button
              data-id="apply-btn"
              variant="Primary"
              size="48"
              stretch
              onClick={onApply}
            >
              {t('apply')}
            </Button>
          </Fragment>
        }
        Content={
          <MultiSelectList
            focusOnMount={!isMobile}
            data-id={dataId}
            isLoading={isLoading}
            onUpdateOptions={(opts) => {
              const filteredOptions = filterOptions(opts);
              setTempSelectedOptions(filteredOptions);
            }}
            options={options}
            selectedOptions={tempSelectedOptions}
            optionRenderer={optionRenderer}
          />
        }
      />
    ) : null;
  }

  return (
    <div
      data-id="dropdown-container"
      sx={{
        position: 'absolute',
        maxHeight: '300px',
        width: ['100%', null, '500px'],
        overflowY: 'auto',
        boxShadow: 'bottom',
        zIndex: 'inputDropdown',
        backgroundColor: 'background',
        padding: '1px',
      }}
    >
      <MultiSelectList
        data-id={dataId}
        isLoading={isLoading}
        onUpdateOptions={onOptionsUpdate}
        options={options}
        selectedOptions={selectedOptions}
        ref={multiSelectListRef}
        optionRenderer={optionRenderer}
      />
    </div>
  );
};
