import { Dayjs } from 'dayjs';
import React, { ReactElement } from 'react';

import { DayGridHeader } from './DayGridHeader';
import { ClassNameProps } from '@ComponentProps';
import { useDate } from '@Dates/useDateHook';

export interface RenderDayParams {
  date: Dayjs;
  dayIndex: number;
}

interface DayGridProps extends ClassNameProps {
  yearMonth: string;
  renderDay: (params: RenderDayParams) => ReactElement;
  displayMonth?: boolean;
}

export const DayGrid: React.FC<DayGridProps> = ({
  yearMonth,
  className,
  renderDay,
  displayMonth = false,
}) => {
  const { formatMonthYear, getDayGridInWeeks } = useDate();
  const dayGridInWeeks = getDayGridInWeeks(yearMonth);
  const emptyDays = dayGridInWeeks[0]?.findIndex(Boolean) || 0;

  return (
    <table
      className={className}
      sx={{
        borderCollapse: 'collapse',
        width: '100%',
      }}
    >
      <DayGridHeader>
        {displayMonth && (
          <strong
            sx={{
              display: 'block',
              textAlign: 'center',
              paddingBottom: 'xs',
              fontSize: 'l',
            }}
          >
            {formatMonthYear(yearMonth)}
          </strong>
        )}
      </DayGridHeader>

      <tbody
        sx={{
          '&::before': {
            display: 'table-row',
            height: '20',
            content: "''",
          },
        }}
      >
        {dayGridInWeeks.map((days, weekIndex) => (
          <tr key={weekIndex}>
            {days.map((date, dayIndex) => (
              <td
                key={dayIndex}
                sx={{
                  position: 'relative',
                  minWidth: '40px',
                  minHeight: '40px',

                  '&::after': {
                    content: "''",
                    display: 'block',
                    paddingBottom: '100%',
                  },
                }}
              >
                {date &&
                  renderDay({
                    date,
                    dayIndex: dayIndex + weekIndex * 7 - emptyDays,
                  })}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
