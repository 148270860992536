import {
  ActionButton,
  Key,
  TriggerButton,
  TriggerEvent,
  keyboardOutlineWithin,
  useClickOutside,
  useOnKeyEvent,
} from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  KeyboardEvent,
  ReactNode,
} from 'react';
import { SxStyleProp } from 'theme-ui';

import { DropdownOrModal } from './DropdownOrModal';
import { MultiSelectOptionType } from './MultiSelectOption';
import { IconInput } from '../IconInput/IconInput';
import { ClassNameProps } from '@ComponentProps';
import { useFocusOut } from '@Components/Modal/useFocusOut';
import { useModal } from '@Components/Modal/useModal';
import { addSentryBreadcrumb } from '@Core/addSentryBreadcrumb';
import { getScrollBehavior } from '@Core/scroll-helpers/getScrollBehavior';
import { useBreakpoint } from '@Core/useBreakpoint';
import { SelectionTag } from '@UX/SelectionTag/SelectionTag';

export interface Option {
  label: string;
  secondaryLabel?: string;
  value: string;
  available?: boolean;
  type?: string;
  onClick?: (option: Option) => void;
}

interface MultiSelectProps extends ClassNameProps {
  options: Option[];
  inputPlaceholder: string;
  inputPlaceholderMobile?: string;
  placeholderAfterSelections?: string;
  icon: React.ReactNode;
  onOptionsUpdate: (selectedOptions: Option[]) => void;
  selectedOptions: Option[];
  modalTitle?: string;
  filterOptions?: (selectedOptions: Option[]) => Option[];
  onOptionsSearchTermChanged?: (searchTerm: string) => void;
  isFlatIcon?: boolean;
  wrapOnDesktop?: boolean;
  optionRenderer?: (props: MultiSelectOptionType) => ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
  isLoading?: boolean;
  onInputClick?: () => void;
}

export const MultiSelect: React.FC<MultiSelectProps> = ({
  children,
  className,
  'data-id': dataId,
  options,
  inputPlaceholder,
  inputPlaceholderMobile,
  placeholderAfterSelections,
  icon,
  onOptionsUpdate,
  selectedOptions = [],
  modalTitle,
  filterOptions,
  onOptionsSearchTermChanged,
  isFlatIcon = false,
  wrapOnDesktop = false,
  optionRenderer,
  onOpen,
  onClose,
  onInputClick,
  isLoading,
}) => {
  const { isMobile } = useBreakpoint();
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const multiSelectRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const searchTermInputRef = useRef<HTMLInputElement>(null);
  const multiSelectListRef = useRef<HTMLDivElement>(null);
  const selectionTagsRef = useRef<HTMLDivElement>(null);
  const mountedRef = useRef<boolean>(false);

  useClickOutside({
    ref: multiSelectRef,
    isActive: isExpanded,
    onClick: () => {
      if (!isMobile()) {
        setIsExpanded(false);
      }
    },
  });

  useFocusOut(multiSelectRef, () => {
    if (isExpanded && !isMobile()) {
      setIsExpanded(false);
    }
  });

  const [isModalOpen, setModalOpen, setModalClose] = useModal();

  const shouldAllowFiltering =
    onOptionsSearchTermChanged && typeof onOptionsSearchTermChanged === 'function';

  useEffect(() => {
    if (isExpanded && onOpen) {
      onOpen();
    }
    if (!isExpanded && onClose) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  useEffect(() => {
    if (isExpanded || isModalOpen) {
      searchTermInputRef?.current?.focus();
    }
  }, [isModalOpen, isExpanded, searchTerm, selectedOptions]);

  useEffect(() => {
    if (mountedRef.current && selectionTagsRef.current?.scroll) {
      selectionTagsRef.current.scroll({
        left: selectionTagsRef.current.scrollWidth,
        behavior: getScrollBehavior(),
      });
    } else {
      mountedRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedOptions)]);

  useOnKeyEvent(Key.Escape, () => {
    setIsExpanded(false);
  });

  const handleInputClick = (event: TriggerEvent, isMobile?: boolean) => {
    if (onOpen) {
      onOpen();
    }

    if (onInputClick) {
      onInputClick();
    }

    if (isMobile) {
      setModalOpen(event);
    } else {
      event.preventDefault();
      if (event.target !== searchTermInputRef?.current) {
        setIsExpanded((prevExpandedState) => !prevExpandedState);
      }
    }
  };

  const handleModalClose = useCallback(() => {
    setModalClose();
    onClose?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchTermInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    setSearchTerm(event.target?.value);
    if (onOptionsSearchTermChanged) {
      onOptionsSearchTermChanged(event.target.value);
    }
  };

  const handleSearchTermInputClick = () => {
    setIsExpanded(true);
  };

  const handleSearchTermKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === Key.Enter) {
      multiSelectListRef.current?.click();
    } else {
      setIsExpanded(true);
    }
  };

  const handleOnPaste = () => {
    setIsExpanded(true);
  };

  const removeOption = (option: Option) => {
    const newOptions = selectedOptions.filter((item) => item.value !== option.value);
    onOptionsUpdate(newOptions);
  };

  const onOptionsUpdated = (opts: Option[]) => {
    onOptionsUpdate(opts);
    if (onOptionsSearchTermChanged) {
      onOptionsSearchTermChanged('');
      setSearchTerm('');
      searchTermInputRef?.current?.focus();
    }
  };

  const getSearchTermInput = (placeholder = inputPlaceholder, styles: SxStyleProp = {}) => (
    <input
      type="text"
      sx={{
        borderWidth: 0,
        flex: [null, '1 0 auto'],
        fontSize: 'm',
        padding: selectedOptions.length > 0 ? 6 : 0,
        width: '100%',
        outline: [null, 'none'],
        marginTop: [0, selectedOptions.length > 0 ? '3xs' : 0],

        '&:focus': {
          outline: 'none',
        },
        '::placeholder': {
          color: 'inputDisabled',
        },
        ...styles,
      }}
      data-id="search-term-input"
      aria-label={selectedOptions.length > 0 ? placeholderAfterSelections : placeholder}
      placeholder={selectedOptions.length > 0 ? placeholderAfterSelections : placeholder}
      onChange={handleSearchTermInputChange}
      onClick={handleSearchTermInputClick}
      onKeyPress={handleSearchTermKeyPress}
      onPaste={handleOnPaste}
      value={searchTerm}
      ref={searchTermInputRef}
    />
  );

  const searchTermInputPlaceholder =
    selectedOptions.length > 0
      ? placeholderAfterSelections && (
          <div
            sx={{
              marginTop: '3xs',
              marginLeft: '3xs',
              color: 'inputDisabled',
            }}
          >
            {placeholderAfterSelections}
          </div>
        )
      : inputPlaceholder;

  return (
    <div
      ref={multiSelectRef}
      sx={{
        position: 'relative',
      }}
    >
      {children ? (
        <TriggerButton
          data-id={dataId}
          className={className}
          onTrigger={(e) => handleInputClick(e, isMobile())}
        >
          {children}
        </TriggerButton>
      ) : (
        <IconInput
          data-id={dataId}
          className={className}
          ref={inputRef}
          icon={icon}
          onClick={(e) => handleInputClick(e, isMobile())}
          sx={{
            position: 'relative',
            ...keyboardOutlineWithin,
          }}
          contentStyles={{
            flexWrap: 'nowrap',
            ...(!!selectedOptions.length && {
              paddingY: '4xs',
              paddingLeft: '4xs',
            }),
          }}
          tabIndex={0}
          isFlatIcon={isFlatIcon}
        >
          <div
            sx={{
              width: '100%',
              overflow: 'hidden',
            }}
          >
            {selectedOptions.length > 0 && (
              <div
                ref={selectionTagsRef}
                sx={{
                  display: 'flex',
                  overflowX: 'scroll',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                  ...(wrapOnDesktop && {
                    flexWrap: ['nowrap', null, 'wrap'],
                    paddingRight: [null, null, '4xs'],
                  }),
                  // this is necessary so that the outline is not cut off
                  padding: '1px',
                }}
              >
                {selectedOptions.map((option, index) => (
                  <SelectionTag
                    key={index}
                    label={option.label}
                    onClick={() => {
                      removeOption(option);
                      addSentryBreadcrumb('Selection tag clicked', { option });
                    }}
                    sx={{
                      marginRight: '4xs',
                      ...(wrapOnDesktop && {
                        width: [null, null, '100%'],
                        marginBottom: [0, null, '4xs'],
                        maxHeight: [null, null, 'none'],
                      }),
                    }}
                  />
                ))}
              </div>
            )}
            {shouldAllowFiltering ? getSearchTermInput() : searchTermInputPlaceholder}
          </div>
        </IconInput>
      )}

      {(isExpanded || isModalOpen) && (
        <DropdownOrModal
          data-id={dataId}
          isLoading={isLoading}
          isMobile={isMobile()}
          onClose={handleModalClose}
          isModalOpen={isModalOpen}
          modalHeaderContent={
            <header
              sx={{
                display: 'flex',
                paddingX: 'xs',
                paddingY: '3xs',
                gap: 's',
                alignItems: 'center',
                backgroundColor: 'backgroundWhite',
              }}
            >
              {shouldAllowFiltering
                ? getSearchTermInput(inputPlaceholderMobile, {
                    padding: '3xs',
                    border: ['2px solid', 'none'],
                    borderColor: 'strokePrimary',
                    borderRadius: '8',
                  })
                : modalTitle}
              <ActionButton
                icon="Navigation/Close"
                type="Icon"
                onClick={handleModalClose}
                ariaLabel={t('close')}
              />
            </header>
          }
          selectedOptions={selectedOptions}
          onOptionsUpdate={onOptionsUpdated}
          options={options}
          filterOptions={filterOptions}
          multiSelectListRef={multiSelectListRef}
          optionRenderer={optionRenderer}
        />
      )}
    </div>
  );
};
