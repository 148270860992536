import { keyboardOutline } from '@loveholidays/design-system';
import React, { forwardRef, MouseEvent, KeyboardEvent } from 'react';
import { SxStyleProp } from 'theme-ui';

import { ClassNameProps } from '@ComponentProps';
import { Key } from '@Core/Key';

const IconInputContent: React.FC<ClassNameProps> = ({ children, className }) => (
  <div
    data-id="icon-input-content"
    className={className}
    sx={{
      flex: '1 1 0',
      flexWrap: 'wrap',
      padding: '2xs',
      paddingRight: 0,
      display: 'flex',
      alignItems: 'center',
      minWidth: '0',
      color: 'inputPlaceholder',
    }}
  >
    {children}
  </div>
);

const IconSection: React.FC<{ isFlatIcon: boolean }> = ({ isFlatIcon = false, children }) => (
  <div
    sx={{
      borderLeftWidth: isFlatIcon ? 0 : 'outlinedStrokeWeight',
      borderLeftStyle: 'solid',
      borderColor: 'inputBorder',
      padding: 10,
      width: '48px',
      minWidth: '42px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      svg: {
        color: isFlatIcon ? 'link' : 'iconDefault',
      },
    }}
  >
    {children}
  </div>
);

type HTMLAttributesOverride = Omit<React.HTMLAttributes<any>, 'onClick'> & {
  onClick?: (e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>) => void;
};

interface IconInputProps extends HTMLAttributesOverride, ClassNameProps {
  icon: React.ReactNode;
  isInvalid?: boolean;
  isFlatIcon?: boolean;
  contentStyles?: SxStyleProp;
}

export const IconInput = forwardRef<HTMLDivElement, IconInputProps>(
  (
    {
      children,
      icon,
      isInvalid,
      isFlatIcon = false,
      'data-id': dataId,
      contentStyles = {},
      onClick = () => {},
      ...otherProps
    },
    ref,
  ) => (
    <div
      tabIndex={0}
      data-id={dataId}
      role="button"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      onKeyUp={(e) => {
        if (e.key === Key.Enter) {
          onClick(e);
        }
      }}
      {...otherProps}
      sx={{
        display: 'flex',
        backgroundColor: 'white',
        justifyContent: 'space-between',
        borderWidth: 'outlinedStrokeWeight',
        borderStyle: 'solid',
        borderColor: isInvalid ? 'inputErrorState' : 'inputBorder',
        borderRadius: isFlatIcon ? 2 : 0,
        minHeight: '48px',
        cursor: 'pointer',
        ...keyboardOutline,
      }}
    >
      <IconInputContent sx={contentStyles}>{children}</IconInputContent>

      <IconSection isFlatIcon={isFlatIcon}>{icon}</IconSection>
    </div>
  ),
);
